import React, { useEffect, useState } from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import styled from 'styled-components'
import { FormattedMessage as FM } from 'react-intl'
import { Text, Box, Flex, Grid } from '@makerdao/ui-components-core'

import getUrls from '../urls'
import { blogLangs, getMessage } from '../i18n'

import Layout, { adjustedTheme } from '../components/layout'
import { OneColumnLayout } from '../components/display/oneColumn'
import Link from '../components/link'
import {
  flexCenterContent,
  absoluteLayer,
  transition,
} from '../components/css-mixins'

import VimeoPlayer from '../components/vimeoPlayer'

import triangleIcon from '../images/imported/icons/triangleIcon.inline.svg'
import triangleIconFilled from '../images/imported/icons/triangleIcon_filled.inline.svg'
import DaiIcon from '../images/imported/icons/daiIcon.svg'
import DashingDai from '../images/imported/landing/dashingDai.svg'
import DaiEqualsDollar from '../images/imported/landing/daiEqualsDollar.svg'
import MakerRound from '../images/imported/landing/makerRound.svg'
import Chat from '../images/imported/landing/chat.svg'
import Forum from '../images/imported/landing/forum.svg'
import Github from '../images/imported/landing/github.svg'
import Telegram from '../images/imported/landing/telegram.svg'
import Twitter from '../images/imported/landing/twitter.svg'

const LINK_COLOR = adjustedTheme.colors.teal['500'] // Teal 500
const LINK_COLOR_HOVER = '#10685E'
const HERO_COLOR_HOVER = adjustedTheme.colors.teal['700']

const GradientBackground = (() => {
  const GradientBackgroundStyle = styled.div`
    z-index: -1;
    top: 0;
    height: 593px;
    width: 100%;
    overflow: hidden;

    &,
    .images,
    .images > * {
      position: absolute;
    }

    .images {
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      min-width: 800px;
    }

    .top-right {
      top: 0;
      right: 0;
    }

    .top-left {
      top: 0;
      left: 0;
    }

    @media (max-width: 1000px) {
      .top-left {
        left: -320px;
      }
    }

    .bottom-left {
      bottom: 0;
      left: -111px;
    }
  `

  return ({ data, ...props }) => {
    return (
      <GradientBackgroundStyle {...props}>
        <div className="images">
          <Img
            className="top-right"
            style={{ position: 'absolute' }}
            fixed={data.heroBgTopRight.childImageSharp.fixed}
          />
          <Img
            className="top-left"
            style={{ position: 'absolute' }}
            fixed={data.heroBgTopLeft.childImageSharp.fixed}
          />
        </div>
      </GradientBackgroundStyle>
    )
  }
})()

const Hero = styled(Box)`
  margin-top: 99px;
  margin-bottom: 52px;

  h1 {
    line-height: 42px;
    font-weight: 500;
    max-width: 780px;
    margin-bottom: 28px;
    margin-top: 30px;
    font-size: 44px;
  }

  .subheadline {
    font-size: 21px;
    letter-spacing: 0.3px;
    line-height: 31px;
  }

  .call-to-actions {
    font-weight: 500;
    margin-top: 40px;
    letter-spacing: 0;
    font-size: 19px;

    a {
      text-decoration: none;
    }

    .text-height {
      height: 26px;
      display: inline-block;
    }

    .button {
      display: inline-flex;
      ${flexCenterContent};
      width: 160px;
      height: 48px;
      color: #fff;
      background: linear-gradient(101.31deg, #1AAB9B -2%, #2EB9AA 99.17%);
      border-radius: 55px;
      ${transition};
      box-shadow: 0 1px 4px rgba(138, 138, 138, 0.25);

      :hover {
        background: linear-gradient(101.31deg,  ${HERO_COLOR_HOVER} -2%, #2ca394 99.17%);
      }
    }

    .play-vid {
      margin-left: 30px;
      color: ${LINK_COLOR};
      position: relative;
      cursor: pointer;
      padding-left: 2.6rem;
      ${transition};

      & span:before,
      & span:after {
        content: '';
        position: absolute;
        top: 8px;
        left: 0;
        width: 14px;
        height: 13px;
      }

      & span:before {
        mask: center center no-repeat;

        mask-image: url('${triangleIcon}');
        background-color: ${LINK_COLOR};
      }

      & span:after {
        transition: 0.12s ease-out;
        mask: center center no-repeat;

        mask-image: url('${triangleIconFilled}');
        background-color: ${LINK_COLOR};
        clip-path: inset(0% 100% 0% 0%);
      }

      &:hover {
        color: ${HERO_COLOR_HOVER};
        span:after {
          transform: unset;
          clip-path: inset(0%);
        }
        span:after, span:before {
          background-color: ${HERO_COLOR_HOVER};
        }
      }

    }
  }

  @media (min-width: ${props => props.theme.breakpoints.m}) {
    margin-top: 152px;
    margin-bottom: 190px;

    h1 {
      margin-bottom: 19px;
    }

    .call-to-actions {
      margin-top: 31px;
    }
  }
`

const AcrossBanner = (() => {
  const itemWidth = '720px' // the image and the text box
  const AcrossBannerStyle = styled(Flex)`
    width: 100%;
    flex-direction: column;

    .left-side {
      width: 100%;
      height: 293px;
      display: flex;
      justify-content: flex-end;
    }

    .image-extension {
      width: 502px;
    }

    .image {
      flex-shrink: 0;
      height: 100%;
      width: 100%;
      max-width: ${itemWidth};
      position: relative;

      .background-layer {
        ${absoluteLayer};
        z-index: -1;
        overflow: hidden;
        .background-image {
          width: 100%;
          margin: 0 auto;
        }
      }

      .front-layer {
        ${absoluteLayer};
        ${flexCenterContent};
        z-index: 1;
      }
    }

    .dai-icon {
      width: 150px;
      height: 150px;
    }

    .right-side {
      width: 100%;
      background-color: #566976;
    }

    .text {
      height: 100%;
      width: 100%;
      max-width: ${itemWidth};
      color: #fff;
      padding: 56px 26px;
      ${flexCenterContent};

      .text-content {
        max-width: 470px;
        padding-bottom: 4px;
        padding-right: 4px;
        h2 {
          color: #fff;
          font-weight: 500;
          font-size: 24px;
          margin-bottom: 13px;
          letter-spacing: 0.2px;
          max-width: 220px;
        }
        p {
          font-size: 20px;
        }
      }
    }

    @media (min-width: 400px) {
      .text .text-content h2 {
        max-width: unset;
      }
    }

    @media (min-width: 550px) {
      .dai-icon {
        width: 26.5%;
        height: unset;
      }
    }

    /* Switch to vertical layout */
    @media (min-width: ${itemWidth}) {
      flex-direction: row;
      height: 379px;
      .left-side,
      .right-side {
        width: 50%;
        height: 100%;
      }

      .text {
        padding: 5px 20px 5px 30px;
      }

      .dai-icon {
        width: 190px;
        height: 190px;
      }
    }

    @media (min-width: 2472px) {
      .image-extension {
        flex: 1;
      }
    }
  `

  return ({ data }) => (
    <AcrossBannerStyle>
      <div className="left-side">
        <Box className="image-extension">
          <Img
            fixed={data.middleBackgroundExtension.childImageSharp.fixed}
            style={{ height: '100%', display: 'block', width: 'unset' }}
          />
        </Box>
        <Box className="image">
          <Flex className="background-layer">
            <Img
              className="background-image"
              fixed={data.middleBackground.childImageSharp.fixed}
              style={{ height: '100%' }}
            />
          </Flex>
          <Flex className="front-layer">
            <DaiIcon className="dai-icon" />
          </Flex>
        </Box>
      </div>
      <div className="right-side">
        <Flex className="text">
          <Box className="text-content">
            <h2>
              <FM id="landing.section1-title" />
            </h2>
            <p>
              <FM id="landing.section1-content" />
            </p>
          </Box>
        </Flex>
      </div>
    </AcrossBannerStyle>
  )
})()

const TextWithImgSection = styled(Flex)`
  margin-top: ${props => props.mt || '49px'};
  justify-content: space-between;
  max-width: 805px;
  flex-direction: column-reverse;
  align-items: center;

  .content {
    max-width: 455px;
    text-align: center;
    h2 {
      font-weight: 500;
      font-size: 23px;
      letter-spacing: 0.3px;
    }
    p {
      font-size: 20px;
      line-height: 31px;
      margin-top: 23px;
    }
    .links {
      margin-top: 26px;
    }
    a {
      font-size: 19px;
      font-weight: 500;
      line-height: 23px;
      letter-spacing: 0.3px;
      color: ${LINK_COLOR};
      text-decoration: none;

      :hover {
        color: ${LINK_COLOR_HOVER};
      }
    }
  }

  .img {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 245px;
    margin-bottom: 23px;
  }

  @media (min-width: ${props => props.theme.breakpoints.l}) {
    flex-direction: row;
    align-items: unset;
    margin-top: ${props => props.mt || '113px'};

    .content {
      text-align: left;
      p {
        margin-top: 17px;
      }
      .links {
        margin-top: 14px;
      }
    }
    .img {
      margin-bottom: unset;
    }
  }
`

const CommunityAndBlog = (() => {
  function truncateTitle(string, length = 85) {
    const ending = '...'

    if (string.length > length) {
      // don't cut a word in half
      let lastCharIndex
      for (
        lastCharIndex = length - ending.length - 1;
        lastCharIndex > 0;
        lastCharIndex--
      ) {
        if (string[lastCharIndex] === ' ') {
          break
        }
      }
      if (lastCharIndex === 0) {
        // it's a very long word (should be very rare)
        return string.substring(0, length - ending.length) + ending
      }
      return string.substring(0, lastCharIndex + 1) + ending
    } else {
      return string
    }
  }

  function getBlogPosts(data, locale) {
    return data.allWordpressPosts.edges
      .map(edge => edge.node)
      .filter(post => post.lang === (blogLangs[locale] || 'en'))
      .slice(0, 3)
      .sort((postA, postB) => new Date(postB.date) - new Date(postA.date))
  }

  function renderDate(date, locale) {
    return new Date(date).toLocaleDateString(locale, {
      day: 'numeric',
      month: 'long',
      year: 'numeric',
    })
  }

  const CommunityAndBlogStyle = styled(Box)`
    position: relative;
    padding: 49px 10px 0;
    max-width: 1100px;
    margin: 80px auto 74px;
    text-align: center;

    &,
    p,
    h4 {
      color: #000;
    }

    h4 {
      font-weight: 500;
      /* todo: unify code with main sections' h4 */
      font-size: 24px;
      line-height: 29px;
      letter-spacing: 0.4px;
      margin: 19px;
    }

    p {
      font-size: 20px;
      line-height: 31px;
      letter-spacing: 0.4px;
      max-width: 640px;
      margin: 0 auto;
    }

    :after {
      content: '';
      display: block;
      background: rgba(209, 222, 230, 0.14);
      position: absolute;

      top: 0;
      left: 0;
      right: 0;
      bottom: 100px;
      z-index: -1;

      @media (min-width: 1100px) {
        border-radius: 10px;
      }
    }

    @media (min-width: ${props => props.theme.breakpoints.m}) {
      padding: 49px 41px 0;
    }

    @media (min-width: ${props => props.theme.breakpoints.xl}) {
      padding: 49px 57px 0;
    }
  `

  const CommunityLinks = styled(Grid)`
    grid-template-columns: repeat(2, 1fr);
    grid-row-gap: 40px;
    justify-items: center;
    margin: 59px auto 88px;

    @media (min-width: ${props => props.theme.breakpoints.m}) {
      grid-template-columns: repeat(3, 1fr);
    }

    @media (min-width: ${props => props.theme.breakpoints.l}) {
      grid-template-columns: repeat(5, 1fr);
    }
  `

  const CommunityLink = styled(Link)`
    font-weight: 500;
    font-size: 17px;
    line-height: 20px;
    letter-spacing: 0.3px;
    text-decoration: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 16px 15px 17px;
    min-width: 85px;
    border-radius: 8px;
    ${transition};
    background-color: rgba(0, 0, 0, 0);

    :hover {
      background-color: #eff2f4;
    }
  `

  const LinkImg = styled(Flex)`
    height: 25px;
    align-items: center;
    margin-bottom: 10px;

    svg {
      display: block;
    }
  `

  const BlogCards = styled(Grid)`
    align-items: center;
    justify-items: stretch;
    max-width: 300px;
    grid-template-columns: 1fr;
    grid-row-gap: 34px;
    margin: 43px auto 0;

    @media (min-width: ${props => props.theme.breakpoints.l}) {
      max-width: unset;
      grid-column-gap: 30px;
      grid-template-columns: 1fr 1fr 1fr;
    }

    @media (min-width: ${props => props.theme.breakpoints.xl}) {
      grid-column-gap: 41px;
    }
  `

  const BlogCard = styled(Link)`
    max-width: 300px;
    min-height: 366px;
    background: #fff;
    border: 1px solid #d1dee6;
    border-radius: 8px;
    text-decoration: none;
    text-align: left;
    color: #000;
    ${transition};
    box-shadow: 0 0 0 rgba(0, 0, 0, 0);
    overflow: hidden;
    :hover {
      border: 1px solid #9fafb9;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.04);
      color: #000;
    }

    .img-container {
      height: 254px;
    }

    .text-part {
      padding: 13px 19px 14px;
    }

    .title {
      font-size: 17px;
      line-height: 23px;
      height: 46px;
       {
        /* double line-height */
      }
      letter-spacing: 0.3px;
      overflow: hidden;
    }

    .date {
      margin-top: 6px;
      font-size: 15px;
      line-height: 31px;
      letter-spacing: 0.2px;
      color: #868686;
      opacity: 0.7;
    }
  `

  return ({ data, locale, ...props }) => {
    const urls = getUrls(locale)

    return (
      <CommunityAndBlogStyle {...props}>
        <h4>
          <FM id="community-headline" />
        </h4>
        <p>
          <FM id="community-subheadline" />
        </p>
        <CommunityLinks>
          <CommunityLink to={urls('Chat')} style={{ color: '#C5362E' }}>
            <LinkImg>
              <Chat />
            </LinkImg>
            <FM id="menu-Chat" />
          </CommunityLink>
          <CommunityLink to={urls('Forum')} style={{ color: '#231F20' }}>
            <LinkImg>
              <Forum />
            </LinkImg>
            <FM id="menu-Forum" />
          </CommunityLink>
          <CommunityLink to={urls('Twitter')} style={{ color: '#4AA1EC' }}>
            <LinkImg>
              <Twitter />
            </LinkImg>
            <FM id="Twitter" />
          </CommunityLink>
          <CommunityLink to={urls('Telegram')} style={{ color: '#259DD0' }}>
            <LinkImg>
              <Telegram />
            </LinkImg>
            <FM id="Telegram" />
          </CommunityLink>
          <CommunityLink to={urls('Github')} style={{ color: '#1B1F23' }}>
            <LinkImg>
              <Github />
            </LinkImg>
            <FM id="GitHub" />
          </CommunityLink>
        </CommunityLinks>
        <h4>
          <FM id="landing.blog-headline" />
        </h4>
        <BlogCards>
          {getBlogPosts(data, locale).map((post, index) => (
            <BlogCard to={post.link} key={index}>
              <Box className="img-container">
                {post.mediaFile ? (
                  <Img
                    fixed={post.mediaFile.childImageSharp.fixed}
                    style={{ minHeight: '200px' }}
                  />
                ) : (
                  <Img fixed={data[`blog${index + 1}`].childImageSharp.fixed} />
                )}
              </Box>
              <Box className="text-part">
                <div className="title">
                  {truncateTitle(post.title.rendered, 68)}
                </div>
                <div className="date">{renderDate(post.date, locale)}</div>
              </Box>
            </BlogCard>
          ))}
        </BlogCards>
      </CommunityAndBlogStyle>
    )
  }
})()

const IndexPage = ({ data, pageContext: { locale } }) => {
  const urls = getUrls(locale)
  const [showVideo, setShowVideo] = useState(false)

  return (
    <div>
      <GradientBackground data={data} />
      <div style={{ position: 'relative' }}>
        <Layout
          locale={locale}
          meta={{
            title: getMessage('landing.meta.title', locale),
            // use the default description
          }}
        >
          <VimeoPlayer
            id="411464106"
            showVideo={showVideo}
            onCloseVideo={() => setShowVideo(false)}
          />
          <OneColumnLayout
            maxWidth="1046px"
            pl={['20px', '20px', '97px']}
            pr="20px"
            minHeight="unset"
          >
            <Hero>
              <h1>
                <FM id="landing.headline" />
              </h1>
              <Box className="subheadline">
                <FM id="landing.subheadline" />
              </Box>
              <Box className="call-to-actions">
                <a
                  className="button"
                  href={'https://spark.fi/'}
                  target="_blank"
                >
                  <Box className="text-height">
                    <FM id="landing.hero-button" />
                  </Box>
                </a>
                <span className="play-vid" onClick={() => setShowVideo(true)}>
                  <Box className="text-height">
                    <FM id="landing.hero-play-vid" />
                  </Box>
                </span>
              </Box>
            </Hero>
          </OneColumnLayout>
          <AcrossBanner data={data} />
          <OneColumnLayout
            maxWidth="1046px"
            pl={['20px', '20px', '97px']}
            pr="20px"
          >
            <TextWithImgSection mt={{ s: '55px', l: '132px' }}>
              <Box className="content" style={{ maxWidth: '430px' }}>
                <h2>
                  <FM id="landing.section2-title" />
                </h2>
                <p>
                  <FM id="landing.section2-content" />
                </p>
                <Box className="links">
                  <Link to={urls('Whitepaper')}>
                    <FM id="landing.section2-link" />
                  </Link>
                </Box>
              </Box>
              <Box className="img">
                <DaiEqualsDollar />
              </Box>
            </TextWithImgSection>
            <TextWithImgSection>
              <Box className="content">
                <h2>
                  <FM id="landing.section5-title" />
                </h2>
                <p>
                  <FM id="landing.section5-content" />
                </p>
                <Box className="links">
                  <Link to={urls('Governance')}>
                    <FM id="landing.section5-link" />
                  </Link>
                </Box>
              </Box>
              <Flex
                className="img"
                height="170px"
                alignItems="center"
                pb="10px"
              >
                <MakerRound />
              </Flex>
            </TextWithImgSection>
            <TextWithImgSection>
              <Box className="content">
                <h2>
                  <FM id="landing.section6-title" />
                </h2>
                <p>
                  <FM id="landing.section6-content" />
                </p>
              </Box>
              <Box className="img" pt="12px" style={{ marginBottom: '63px' }}>
                <img src="/ecosystem.png" alt="Ecosystem" />
              </Box>
            </TextWithImgSection>
          </OneColumnLayout>
          <CommunityAndBlog data={data} locale={locale} />
          {locale === 'ja' ? (
            <Box mt="10px" mx="auto" px="10px" maxWidth="900px">
              <Text fontSize="1.1rem">
                <FM id="landing.japan-disclaimer1" />
                <br />
                <FM id="landing.japan-disclaimer2" />
                <br />
                <FM id="landing.japan-disclaimer3" />
              </Text>
            </Box>
          ) : null}
        </Layout>
      </div>
    </div>
  )
}

export default IndexPage

export const query = graphql`
  query {
    ecosystem: file(relativePath: { eq: "landing/ecosystem.png" }) {
      childImageSharp {
        fixed(width: 245, quality: 100) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    heroBgTopLeft: file(relativePath: { eq: "landing/bg-top-left.png" }) {
      childImageSharp {
        fixed(width: 647) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    heroBgTopRight: file(relativePath: { eq: "landing/bg-top-right.png" }) {
      childImageSharp {
        fixed(width: 817, quality: 90) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    middleBackground: file(
      relativePath: { eq: "landing/middle-background.png" }
    ) {
      childImageSharp {
        fixed(width: 720, quality: 90) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    middleBackgroundExtension: file(
      relativePath: { eq: "landing/middle-background-extension.png" }
    ) {
      childImageSharp {
        fixed(width: 502, quality: 82) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    blog1: file(relativePath: { eq: "landing/blog-1.png" }) {
      childImageSharp {
        fixed(width: 298, quality: 100) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    blog2: file(relativePath: { eq: "landing/blog-2.png" }) {
      childImageSharp {
        fixed(width: 298, quality: 100) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    blog3: file(relativePath: { eq: "landing/blog-3.png" }) {
      childImageSharp {
        fixed(width: 298, quality: 100) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    allWordpressPosts {
      edges {
        node {
          title {
            rendered
          }
          link
          date
          lang
          mediaFile {
            childImageSharp {
              fixed(width: 298, height: 254, quality: 100) {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
      }
    }
  }
`
